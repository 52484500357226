
import lnkToDetail from "@/views/heritageUnit/lnkToDetail"
import ShowRelBtn from "@/components/showRelBtn.vue";
import lnkToDocContent from "@/views/officialDoc/lnkToDocContent"
import officialDocSelectAndCreate from "@/views/officialDoc/offcialDocSelectAndCreate.vue";
import LogHistory from "@/views/shared/logHistory.vue";
import Pager from "@/views/layout/pager.vue";
import { ElMessageBox } from "element-plus";
import roles from "@/types/roles.ts";
// import Attach from "./attach.vue";

import { useCityRelTown } from "@/network/chinaArea";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
  watch,
} from "vue";
import {
  useQuery,
  useSetupProjectAction,
  useQueryFileSignManageActionHistory,
} from "@/network/setupProject";
import { openWindowWithToken } from "@/utils/util";
import {
  dicLabel,
  useSelectItemList,
  useSelectItemWithChildList,
  scheduleTypeDic,
  setupProjectYearBatch,
  setupProjectStatusText,
  setupProjectFileSignStatusText,
} from "@/network/lookUp.ts";
import Base from "@/views/layout/Base.vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  props: {
    setupGroup: {
      type: Number,
      default: 1
    }
  },
  components: {
    Base,
    Pager,
    LogHistory,
    officialDocSelectAndCreate,
    ShowRelBtn
    //, Attach
  },
  setup(props) {

    const $reload = inject<any>("$reload");
    watch(() => props.setupGroup, () => {
      $reload();
    })
    const prefix = ref(props.setupGroup == 1 ? "Prov" : "Nation");
    const showBackSubmitDialog = ref(false);
    const backSubmitData = reactive({
      result: true,
      id: null,
      reason: null,
      projectIds: [],
      projects: [],
    });

    const defaultYearBatch =
      setupProjectYearBatch.value &&
      setupProjectYearBatch.value.find((i) => {
        return i.isActive === true;
      })?.yearBatch;
    const downloadYearBatch = ref(defaultYearBatch);
    const user: any = inject("user");
    const activeName = ref(null);
    const router = useRouter();
    const route = useRoute();
    const signList = ref([]);
    const signListProjects = reactive({});
    const fileSignfilter = reactive({
      yearBatch: null,
      status: null,
      city: null,
      setupGroup: props.setupGroup
    });

    const filter = reactive({
      setupProjectProp: null,
      yearBatch: null,
      pageNum: 1,
      pageSize: 20,
      status: null,
      keyword: null,
      city: null,
      town: null,
      projectType: null,
      heritageUnitClass: null,
      isRevolution: null,
      isSafeProject: null,
      setupGroup: props.setupGroup
    });


    if (defaultYearBatch) {
      fileSignfilter.yearBatch = filter.yearBatch = defaultYearBatch;
    }

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town"),
      null
    );

    const dicHeritageClass = useSelectItemList("HeritageClass");
    const manage = (id) => {
      router.push({ name: `ReportAndReview${prefix.value}SetupManage`, params: { id } });
    };
    const [isLoading, pagerData, query, pager] = useQuery(filter);

    const [
      isActionLoading,
      ,
      removeFn,
      ,
      ,
      queryFileSign,
      findFileSignProjects,
      backFileSign,
      deleteFileSign,
    ] = useSetupProjectAction();

    const refBackSubmitDialog = ref(null);

    const beforeBackSubmitDialogClose = () => {
      Object.assign(backSubmitData, {
        id: null,
        reason: null,
        projectIds: [],
        projects: [],
      });

      refBackSubmitDialog.value.clearValidate();
    };
    const doBackSubmit = () => {
      refBackSubmitDialog.value.validate((flag) => {
        if (flag) {
          backFileSign(backSubmitData.id, backSubmitData).then((p) => {
            showBackSubmitDialog.value = false;
            doQueryFileSign();
          });
        }
      });
    };
    const handleBackSubmitDataSelectionChange = (val) => {
      backSubmitData.projectIds = val.map((p) => p.id);
    };

    const backSubmit = (item) => {
      showBackSubmitDialog.value = true;
      backSubmitData.id = item.id;
      expandChange(item).then((p) => {
        backSubmitData.projects = signListProjects[item.id];
      });

      //console.log(backSubmitData.projects);
    };
    const remove = (id) => {
      removeFn(id).then(() => {
        query();
      });
    };

    const expandChange = (row) => {
      if (signListProjects[row.id] == null) {
        signListProjects[row.id] = [];
        return findFileSignProjects(row.id, { onlySelected: true }).then(
          (res) => {
            Object.assign(signListProjects[row.id], res);
            return signListProjects[row.id];
          }
        );
      } else {
        return Promise.resolve(signListProjects[row.id]);
      }
    };

    const createFileSign = () => {
      const params = {
        yearBatch: defaultYearBatch,
        signType: "计划书",
        applyUnitId: user.id,
        applyUnitName: user.name,
        redirect: `/ReportAndReview/${prefix.value}Setup?tab=apply`,
        setupGroup: null,
      };
      params.setupGroup = props.setupGroup;
      sessionStorage.setItem(
        "setupProjectFileSignMange_CreateProps",
        JSON.stringify(params)
      );
      router.push({
        name: "ReportAndReviewSetupFileSignManage",
        params,
      });
    };

    const showLogHistory = ref(false);
    const [logs, queryHistory] = useQueryFileSignManageActionHistory();

    const doQueryHistory = (id) => {
      showLogHistory.value = true;
      queryHistory(id);
    };

    const doQueryFileSign = () => {
      queryFileSign(fileSignfilter).then((p) => {
        signList.value = p.list;
      });
    };
    const doDeleteFileSign = (id) => {
      deleteFileSign(id).then(doQueryFileSign);
    };
    onMounted(() => {
      if (user.inRoles(roles.city)) {
        filter.status = 1;
        activeName.value = "1";
        filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   filter.city = user.city_ID;
        //   stop();
        // });
      } else if (user.inRoles(roles.prov)) {
        activeName.value = "apply";
      } else {
        filter.status = 0;
        activeName.value = "0";
      }
      if (route.query.tab) {
        activeName.value = route.query.tab;
        let s = parseInt(activeName.value)
        if (s)
          filter.status = s
      }
      if (activeName.value == "apply") {
        if (user.inRoles(roles.prov, roles.city)) doQueryFileSign();
      } else {
        query();
      }
    });

    const downloadProvList = (yearBatch) => {
      openWindowWithToken(
        `/api/SetupProject/ExportFileSign${prefix.value}ListByYear/${yearBatch}`
      );
    };

    const downloadList = (item, flag) => {
      openWindowWithToken(
        `/api/SetupProject/ExportFileSign${prefix.value}List/${item.id}/${flag}`
      );
    };

    const downloadPackList = (yearBatch, signId = null) => {
      openWindowWithToken(
        `/api/SetupProject/download${prefix.value}Package/${yearBatch}/${signId ? signId : ''}`
      );
    }
    const downloadWord = (item) => {
      openWindowWithToken(`/api/SetupProject/ExportWord/${item.id}`);
    };

    const downloadListExcel = () => {
      openWindowWithToken(`/api/SetupProject/ExportProject${prefix.value}List`, filter);
    };
    return {
      lnkToDetail,
      prefix,
      lnkToDocContent,
      createFileSign,
      downloadProvList,
      setupProjectYearBatch,
      downloadYearBatch,
      downloadPackList,
      showLogHistory,
      doQueryHistory,
      logs,
      fileSignfilter,
      beforeBackSubmitDialogClose,
      refBackSubmitDialog,
      doBackSubmit,
      handleBackSubmitDataSelectionChange,
      showBackSubmitDialog,
      backSubmitData,
      backSubmit,
      pager,
      remove,
      downloadListExcel,
      downloadList,
      downloadWord,
      expandChange,
      doQueryFileSign,
      doDeleteFileSign,
      findFileSignProjects,
      signList,
      signListProjects,
      setupProjectStatusText,
      setupProjectFileSignStatusText,
      scheduleTypeDic,
      dicHeritageClass,
      activeName,

      dicCity,
      dicTown,

      dicLabel,
      isLoading,
      isActionLoading,
      user,
      roles,
      manage,
      query,
      pagerData,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
